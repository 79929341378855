body {
  min-height: 100vh;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #393939;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  box-sizing: border-box;
  margin: 32px 0 8px 0;
  width: 400px;
  min-height: 320px;
  padding: 16px;
}

p {
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.43;
  margin: 0 3rem 3rem;
  text-align: center;
  white-space: pre-line;
}

input {
  padding: 5px 10px;
  line-height: 1.5rem;
  border-radius: 5px;
  margin: 10px;
  width: calc(100% - 20px);
}

.orgButton {
  background-color: rgba(30, 101, 186, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  height: 45px;
}

.orgButton:hover {
  background-color: rgba(72, 129, 196, 1);
}

.backButton {
  background-color: unset;
  color: rgba(255, 255, 255, 1);
  border: none;
  font-size: 0.8rem;
  cursor: pointer;
}

.backButton:hover {
  color: rgba(255, 255, 255, 0.8);
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
  }
}
